<script setup>
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth.js'
import { useSubscriptionStore } from '~/store/subscription.js'
import { prepareLayout } from '~/utils/auth.js'
const appConfig = useAppConfig()

console.log('rendering layout...')
const { $api } = useNuxtApp()
const store = useAuthStore()
const subscriptionStore = useSubscriptionStore()
const router = useRouter()

const { orgDetails } = usePayments()
const { person, isExternalUser } = storeToRefs(store)
// Define a shared server and client state variable for the location boards in the navigation
// This will initially be empty on first render until the user has been authenticated
const locationsForNav = useState('locations_nav', () => [])

const { data: details } = await useAsyncData('details', () => orgDetails())

prepareLayout(appConfig, store, locationsForNav)

/**
 * Helper that maps a list of location objects to navigation items
 *
 * @param {object[]} locations
 * @returns {object[]}
 */
const mapLocationNavItems = (locations) =>
  locations.map((c) => ({
    name: c.name,
    to: `/board/${c.id}`,
    icon: {
      name: 'material-symbols:line-start-circle-outline-rounded',
      class: 'w-4 h-4',
    },
  }))

let isInactive = false

let isUnpaid = false

const isMultiBoard = ref(details.value.data.multipleBoardsEnabled)
const locationId = ref(details.value.data.location_id)

const createLocationNavigation = (toLocationId) => ({
  name: 'Claims',
  icon: { name: 'ph:stack', class: 'w-5 h-5' },
  activePath: '',
  to: `/board/${toLocationId}`,
})

if (!isMultiBoard.value || isExternalUser.value) {
  const locationNavigation = createLocationNavigation(
    !isMultiBoard.value ? locationId.value : store.person.locations[0]?.id,
  )
  appConfig.tairo.collapse.navigation.items.push(locationNavigation)
}

/**
 * This watcher is triggered on first render and whenever the locationsForNav shared state changes.
 *
 * Because the locations get fetched asynchronously (after the token resolves), the first time this watcher runs,
 * it will have an empty array in locationsForNav.
 */
watch(
  locationsForNav,
  async () => {
    if (isMultiBoard.value && !isExternalUser.value) {
      console.log('watch locations for nav', locationsForNav.value)
      const locationNavigation = {
        name: 'Claims',
        icon: { name: 'ph:stack', class: 'w-5 h-5' },
        activePath: '',
        children: mapLocationNavItems(locationsForNav.value),
      }
      const locationBoardsItemIndex =
        appConfig.tairo.collapse.navigation.items.findIndex((item) =>
          item ? item.name === 'Claims' : false,
        )
      if (locationBoardsItemIndex > -1) {
        appConfig.tairo.collapse.navigation.items.splice(
          locationBoardsItemIndex,
          1,
          locationNavigation,
        )
      } else {
        appConfig.tairo.collapse.navigation.items.push(locationNavigation)
      }
    }
  },
  { immediate: true },
)

console.log('...done rendering layout...')
</script>

<template>
  <div v-if="!store.isLoaded || store.user === null">
    <div
      class="bg-slate-100 dark:bg-[#121212] h-screen flex flex-col justify-center items-center"
    >
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <span
        class="block font-heading text-sm mt-2.5 text-slate-400 dark:text-muted-100"
      >
        Please Wait</span
      >
    </div>
  </div>
  <TairoCollapseLayout v-if="store.isLoaded && store.user !== null">
    <slot />
  </TairoCollapseLayout>
</template>
